// Animations des alertes flash
if($('.alert-flash').length > 0){
  window.setTimeout(function(){
    $('.alert-flash').addClass('alert-flash--closed')
    window.setTimeout(function(){
      $('.alert-flash').remove();
    }, 550)
  },4500);
}

