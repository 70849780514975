let AvancementsGraphique = {

    /*-- Variables ----------------------*/

    Graph: false,       // Instance du graph
    $graph: false,      // L'élément jq
    graphData: false,   // Les données du graphique
    totaux: false,      // Les totaux de commandes

    // Options datepickers
    startDatepickerOptions: {
        format: 'dd/mm/yyyy',
        minView: 2,
        language: 'fr',
        autoclose: true,
        todayBtn: true,
        linkField: "mirror-statistiques-avancements-start",
        linkFormat: "yyyy-mm-dd 00:00:00"
    },

    endDatepickerOptions: {
        format: 'dd/mm/yyyy',
        minView: 2,
        language: 'fr',
        autoclose: true,
        todayBtn: true,
        linkField: "mirror-statistiques-avancements-end",
        linkFormat: "yyyy-mm-dd 23:59:59"
    },

    // Regles d'affichade des tooltips si aire selectionnée
    toolTipCallBackAire: function(tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label;
        var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

        // Loop through all datasets to get the actual total of the index
        var total = 0;
        for (var i = 0; i < data.datasets.length; i++)
            total += data.datasets[i].data[tooltipItem.index];

        // If it is not the last dataset, you display it as you usually do
        if (tooltipItem.datasetIndex != data.datasets.length - 1) {
            return label + " : " + addNumberSeparator(valor)+ ' m²';
        } else { // .. else, you display the dataset and the total, using an array
            return ['Restantes' + " : " + addNumberSeparator(valor)+ ' m²', "Total : " + addNumberSeparator(total)+ ' m²'];
        }
    },

    // Regles d'affichade des tooltips si quantite selectionnée
    toolTipCallBackQuantite: function(tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label;
        var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

        // Loop through all datasets to get the actual total of the index
        var total = 0;
        for (var i = 0; i < data.datasets.length; i++)
            total += data.datasets[i].data[tooltipItem.index];

        // If it is not the last dataset, you display it as you usually do
        if (tooltipItem.datasetIndex != data.datasets.length - 1) {
            return label + " : " + addNumberSeparator(valor);
        } else { // .. else, you display the dataset and the total, using an array
            return ['Restantes' + " : " + addNumberSeparator(valor), "Total : " + addNumberSeparator(total)];
        }
    },

    // Options graph
    graphOptions: {
        type: 'bar',
        options: {
            tooltips: {
                mode: 'label',
                callbacks: {}
            },
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true,
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values){
                            return addNumberSeparator(value);
                        }
                    }
                }]
            },
            maintainAspectRatio: false
        }
    },

    /*-- Fonctions ----------------------*/

    // Fonction init
    init: function(){
        this.setDefaut();

        this.$graph = $('#avancements-graphique');

        this.getGraphData();
        this.setChart();

        this.addEventListeners();
    },

    // Attache les events listenners
    addEventListeners: function(){
        let self = this;

        $('#statistiques-avancements-start-picker').datetimepicker(this.startDatepickerOptions);
        $('#statistiques-avancements-end-picker').datetimepicker(this.endDatepickerOptions);

        $('#statistiques-avancements-search').on('click', function(){
            self.getGraphData();
            self.setChart();
        });

        $('#statistiques-avancements-reset').on('click', function(){
            // Comme le reset declenche l'event change du select matieres, qui recupere et met à jour le graph
            // on a pas besoin de reload les données une deuxieme fois
            self.setDefaut();
            //self.getGraphData();
            //self.setChart();
        });

        $('#statistiques-avancements-start-picker, #statistiques-avancements-end-picker, #statistiques-avancements-matieres').on('change', function(){
            self.getGraphData();
            self.setChart();
        });

        $('#statistiques-avancements-unite').on('change', function(){
            // Mettre l'unite quelque part sans l'objet
            self.getGraphData();
            self.setChart($(this).val());
        });
    },

    // Récupère les statistiques depuis l'api
    getGraphData: function(){
        let self = this;

        let parameters = {
            start: $('#mirror-statistiques-avancements-start').val(),
            end: $('#mirror-statistiques-avancements-end').val(),
            matiere: $('#statistiques-avancements-matieres').val() ? $('#statistiques-avancements-matieres').val() : 0,
            unite: $('#statistiques-avancements-unite').val() ? $('#statistiques-avancements-unite').val() : 0,
        };

        let url = laroute.route('api_statistiques_get_avancements', parameters);
        console.log(url);


        $.get({
            url: url,
            async: false,
            success: function(resp){
                console.log(resp);
                self.graphOptions.data = resp.data;
                self.totaux = resp.totaux;

                if($('#statistiques-avancements-unite').val() == 'aires'){
                    $('li .stat-total').text(addNumberSeparator(self.totaux.total) + ' m²');
                    $('li .stat-manquant').text(addNumberSeparator(self.totaux.manquant) + ' m²');
                    $('li .stat-realise').text(addNumberSeparator(self.totaux.realise) + ' m²');
                } else {
                    $('li .stat-total').text(addNumberSeparator(self.totaux.total) + ' pièces');
                    $('li .stat-manquant').text(addNumberSeparator(self.totaux.manquant) + ' pièces');
                    $('li .stat-realise').text(addNumberSeparator(self.totaux.realise) + ' pièces');
                }

            }
        });

        //self.graphOptions.data = repApi2.data;
        //self.graphOptions.options = repApi2.options;
    },

    // Initialise le graphique
    setChart: function(unite){
        unite = unite ? unite : false;

        if(unite == 'aires'){
            this.graphOptions.options.tooltips.callbacks.label = this.toolTipCallBackAire;
        } else {
            this.graphOptions.options.tooltips.callbacks.label = this.toolTipCallBackQuantite;
        }

        this.$graph = $('#avancements-graphique');
        console.log(this.graphOptions);

        if(this.Graph){
            this.Graph.config.data = this.graphOptions.data;
            this.Graph.update();
        } else {
            this.Graph = new Chart($('#avancements-graphique'), this.graphOptions);
        }
    },

    // Remplis les champs de recherche avec les valeurs par défaut
    setDefaut: function(){
        let Start = moment();
        let End = moment();

        Start.subtract(7, 'days');

        let start = Start.format('DD/MM/YYYY');
        let startMirror = Start.format('YYYY-MM-DD 00:00:00');
        $('#statistiques-avancements-start-picker').val(start);
        $('#mirror-statistiques-avancements-start').val(startMirror);

        let end = End.format('DD/MM/YYYY');
        let endMirror = End.format('YYYY-MM-DD 23:59:59');
        $('#statistiques-avancements-end-picker').val(end);
        $('#mirror-statistiques-avancements-end').val(endMirror);

        $('#statistiques-avancements-matieres option[value=0]').prop('selected', 'selected')
        $('#statistiques-avancements-matieres').trigger('change');
    },

};


let repApi2 = {
    data: {
        labels: [
            "Vinyle",
            "Plexi 4mm",
            "PS 1mm",
        ],
        datasets: [
            {
                label: "Réalisées",
                data: [
                    10,
                    20,
                    30
                ],
                backgroundColor: [
                    "rgba(255, 0, 0, 0.7)",
                    "rgba(3, 169, 244, 0.7)",
                    "rgba(244, 67, 54, 0.7)",
                ],
                borderWidth: 2
            },
            {
                label: "Réalisées",
                data: [
                    40,
                    40,
                    40
                ],
                borderWidth: 2
            }
        ]
    },
}

if(document.getElementById("avancements-graphique")){
    AvancementsGraphique.init();
}


function addNumberSeparator(nStr)
{
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }
    return x1 + x2;
}