// Attache les events necessaires aux inputs
function addCommandeFormEvents () {

    let dateTimePickerSettings = {
        format: 'dd/mm/yyyy',
        minView: 2,
        language: 'fr',
        autoclose: true,
        todayBtn: true,
        linkField: "mirror-commande-date",
        linkFormat: "yyyy-mm-dd"
    };

    let expeditionDateTimePickerSettings = {
        format: 'dd/mm/yyyy',
        minView: 2,
        language: 'fr',
        autoclose: true,
        todayBtn: true,
        linkField: "mirror-commande-expedition-date",
        linkFormat: "yyyy-mm-dd"
    };


    let $CommandeExpeditionDatePicker = $("#commande-expedition-date-picker");
    let $CommandeDatePicker           = $("#commande-date-picker");
    let $CommandeForm                 = $('#commande-form');
    let $CommandeEditForm             = $('#commande-edit-form');
    let $PercageDiametre              = $('#input-percage-diametre');
    let $Finitions                    = $('#form-group-finition');
    let $InputFinition                = $('#input-finition');
    let $CheckboxPercage              = $('#percage');
    let $InputMatiere                 = $('#input-matiere');

    var editMode          = $('#commande-edit-form').length > 0;
    var selectedMatiereId = editMode ? $InputFinition.val() : false;

    $CommandeDatePicker.datetimepicker(dateTimePickerSettings);
    if ($CommandeDatePicker.val()) {
        dateTimePickerSettings.defaultDate = $CommandeDatePicker.val();
    }

    if ($('#input-urgent').is(':checked')) {
        $CommandeExpeditionDatePicker.datetimepicker(expeditionDateTimePickerSettings);
    }

    // Gestion champ date expedition
    $('#input-urgent').on('change', function () {
        if ($(this).prop('checked')) {
            $CommandeExpeditionDatePicker.datetimepicker(expeditionDateTimePickerSettings);
            $CommandeExpeditionDatePicker.attr('placeholder', "Date d'expédition demandée");
            // $CommandeExpeditionDatePicker.prop('disabled', false)
        } else {
            $CommandeExpeditionDatePicker.datetimepicker('remove');
            $CommandeExpeditionDatePicker.attr('placeholder', 'Non spécifiée');
            $CommandeExpeditionDatePicker.val('');
            $('#mirror-commande-expedition-date').val('');
            // $CommandeExpeditionDatePicker.prop('disabled', true)
        }
    })

    // Verification de la matiere
    // function validateMatiereInput(){
    //   return 0;
    // }

    // Gestion champ percage
    $CheckboxPercage.on('change', function () {
        if ($(this).prop('checked')) {
            $PercageDiametre.attr('placeholder', "Diamètre de perçage");
            $PercageDiametre.prop('disabled', false)
        } else {
            $PercageDiametre.attr('placeholder', 'Pas de perçage');
            $PercageDiametre.val('');
            $PercageDiametre.prop('disabled', true)
        }
    })

    if ($('#input-matiere').val() != 15) {
        $('#input-matiere-custom').hide();
        $('#input-matiere-custom input').val('');
    }

    // Chargement des finitions selon les matieres
    $('#input-matiere').on('change', function () {
        // Si matière custom selectionné dans le select des matières
        if ($(this).val() == 15) {
            //$Finitions.hide();
            $('#input-matiere-custom').show();

        } else {
            $('#input-matiere-custom').hide();
            $('#input-matiere-custom input').val('');
            $.get({
                url: $(this).find(':selected').attr('data-get-finitions-url'),
                async: false,
                success: function (jsonData) {
                    let data = $.parseJSON(jsonData)
                    updateFinitions(data, editMode, selectedMatiereId);
                }
            });
        }
    });


    // Fonction de reecriture du select Finitions
    function updateFinitions (finitions, editMode = false, selectedId = false) {
        var options = "<option></option>";

        console.log(selectedId);
        console.log(finitions);

        $InputFinition.html('');

        $.each(finitions, function (index, finition) {

            if (editMode && !finition.active && finition.id != selectedId) {
                return;
            }

            options += "<option value=" + finition.id + ">" + finition.display_name + "</option>";
        })

        $InputFinition.append(options);
    }

    let finitionSelected = $InputFinition.val();

    console.log(finitionSelected);

    $('#input-matiere').trigger('change');
    $InputFinition.val(finitionSelected);

    // Update largeur et longueur si dimensionFormat choisi
    $('#dimension_format_id').on('change', function () {
        if ($(this).val() != 0) {
            let largeur  = $(this).children('option:selected').data('largeur');
            let longueur = $(this).children('option:selected').data('longueur');

            $('#longueur').val(longueur);
            $('#largeur').val(largeur);
        } else {
            $('#longueur').val(0);
            $('#largeur').val(0);
        }
    });

    $('form #longueur, .modal #largeur').on('change', function () {
        $('#dimension_format_id').val(0);
    })


    // AJAX ADD
    $CommandeForm.validator().on('submit', function (e) {
        if (validateMatiereInput() == -1 || e.isDefaultPrevented()) {
            e.preventDefault();
            return;
        }
        e.preventDefault();
        let command = $(this).serialize();

        //command += '&commande_date=' + $('#mirror-commande-date').val();

        if ($('#dimension_format_id').val() != 0) {
            command += "&dimension_format_id=" + $('#dimension_format_id').val();
        }

        if ($('#input-urgent').prop('checked')) {
            command += '&urgence=1&expedition_date=' + $('#mirror-commande-expedition-date').val();
        } else {
            command += "&urgence=0&expedition_date=0000-00-00";
        }

        console.log(command);
        $.ajax({
            url: $CommandeForm.attr('action'),
            method: 'POST',
            data: command,
            success: function (data) {
                // alert('Commande ajoutée');
                // On redirige vers l'url de la nouvelle commande, recupérée en reponse
                window.setTimeout(function () {
                    location.replace(data);
                }, 500);
            },
            error: function (err) {
                alert('Erreur lors de l\'ajout');
                // console.log(err);
            }
        })
    })

    // AJAX EDIT
    $CommandeEditForm.validator().on('submit', function (e) {
        if (validateMatiereInput() == -1 || e.isDefaultPrevented()) {
            e.preventDefault();
            return;
        }
        e.preventDefault();

        let command = $('#commande-edit-form').serialize();

        if ($('#input-urgent').prop('checked')) {
            command += '&urgence=1&expedition_date=' + $('#mirror-commande-expedition-date').val();
        } else {
            command += "&urgence=0&expedition_date=0000-00-00";
        }

        //if ($CommandeDatePicker.val()) {
        //    let commandeDate = $('#mirror-commande-date').val();
        //    command += "&commande_date=" + commandeDate;
        //}

        if ($('#dimension_format_id').val() != 0) {
            command += "&dimension_format_id=" + $('#dimension_format_id').val();
        }

        command += "&finition_id=" + ($InputFinition.val() ? $InputFinition.val() : '');
        console.log(command);

        if ($CheckboxPercage.prop('checked') == false) {
            command += "&percage_diametre=&percage=0";
        }


        $.ajax({
            url: $CommandeEditForm.attr('action'),
            method: 'POST',
            data: command,
            success: function (data) {
                window.setTimeout(function () {
                    location.reload()
                }, 350);
            },
        })
    })
}

function validateMatiereInput () {
    if ($('#input-matiere').val() == -1) {
        $('#input-matiere').css('border', '1px solid #a94442');
        $('label[for=input-matiere]').css('color', '#a94442');
        return -1;
    }
    $('#input-matiere').css('border', '1px solid rgb(204, 204, 204)');
    $('label[for=input-matiere]').css('color', '#333');
    return 0;
}


addCommandeFormEvents();
