let dateDebutPickerSettings =
    {
        format: 'dd/mm/yyyy',
        minView: 2,
        language: 'fr',
        autoclose: true,
        todayBtn: true,
        linkField: "mirror-production-date-debut",
        linkFormat: "yyyy-mm-dd",
    };

let dateFinPickerSettings =
    {
        format: 'dd/mm/yyyy',
        minView: 2,
        language: 'fr',
        autoclose: true,
        todayBtn: true,
        linkField: "mirror-production-date-fin",
        linkFormat: "yyyy-mm-dd"
    };

let PRODUCTION_TABLE_OPTIONS = {
    language: {
        "url": "../../lang/French.json"
    },
    "oColReorder": {
        "realtime": true
    },
    lengthMenu: [
        [5, 10, 15],
        [5, 10, 15]
    ],
    // dom: "rti",
    // paging: true,
    columnDefs: [
        {targets: 'no-sort', orderable: false},
    ],
    // 'stateSave': true,
    "order": [[1, "asc"]],
    "iDisplayLength": 5,
    "searching": false,
};


let PRINTABLE_TABLE_OPTIONS = {
    language: {
        "url": "../../lang/French.json"
    },
    "oColReorder": {
        "realtime": true
    },
    columnDefs: [
        {targets: 'no-sort', orderable: false},
        {width: 25, targets: 0}
    ],
    dom: "rtip",
    "searching": false,
    "paging": false,
    "order": [[4, "asc"]],
};


$('#production-date-debut').datetimepicker(dateDebutPickerSettings);
$('#production-date-fin').datetimepicker(dateFinPickerSettings);

// On attache l'ajax aux différents champs

$('#production-date-fin').on('change', function () {
    if ($('#mirror-production-date-debut').val() == '') {
        alert('Merci de préciser une date de début');
        return
    }
})


// Bouton reset date
$('#production-reset').on('click', function () {
    resetProductionDatesFields();
});

$('#production-commandes-search').on('click', function () {
    getProductionCommandes();
});

var lastData = null;
var newCommandAdded = false;


function setDateDebutInput() {
    if ($('#production-realises-select').length > 0) {
        if ($('#production-realises-select').val() == 'non-realisees') {
            $('#mirror-production-date-debut').val(getLastYearDate());
            $('#production-date-debut').val(getLastYearDateFormated());
            $('#mirror-production-date-fin').val(getTodayDate());
            $('#production-date-fin').val(getTodayDateFormated());
            getProductionCommandes();
        } else {
            $('#mirror-production-date-debut').val(getTodayDate());
            $('#production-date-debut').val(getTodayDateFormated());
            $('#mirror-production-date-fin').val(getTodayDate());
            $('#production-date-fin').val(getTodayDateFormated());
            getProductionCommandes();
        }
    }
}

// Fonction de récupération des commandes en prod
function getProductionCommandes() {
    let dateDebut = $('#mirror-production-date-debut').val();
    let dateFin = $('#mirror-production-date-fin').val();

    let url = $('#production-date-debut').data('url');
    let sendData = {};
    sendData.realisees = $('#production-realises-select').val();
    if (dateDebut) {
        sendData.from = dateDebut;
    }
    if (dateFin) {
        sendData.to = dateFin;
    }

    $.get({
        url: url,
        data: sendData,
        async: true,
        success: function (data) {

            $('.production-commandes-container').html(data);
            refreshProductionTables()
        },
        error: function (e) {
        }
    });
}

function resetProductionDatesFields() {
    // $('#mirror-production-date-debut').val('');
    // $('#production-date-debut').val('');
    // $('#mirror-production-date-fin').val('');
    // $('#production-date-fin').val('');
    $('#production-realises-select').val('non-realisees');
    setDateDebutInput();
}


function attacherRealiserEvents() {
    $('.commande-realiser-quantite').on('click', function (e) {
        e.preventDefault();

        let id = $(this).data('id');
        let realiser = $(this).data('realisee') == 1 ? 0 : 1;
        let quantite_realisee = $(this).data('quantite-realisee');
        let quantite = $(this).data('quantite');
        let swalTemplate = '<div>\n  <div class="row">\n    <div class="col-md-6 col-md-push-1">\n      <label for="realiser" class="form-label" style="font-size: 14px">Pièces réalisées: </label>\n    </div>\n    <div class="col-md-5">\n      <div class=\'input-group\'>\n        <input type=\'number\' min=0 step=1 value="" class=\'form-control\' id=\'quantite-realiser\'>\n        <span class=\'input-group-addon swal-quantite-addon\'>/</span>\n      </div>\n    </div>\n  </div>\n</div>\n\n<styles>\n.swal2-title{\n  font-size: 18px;\n  }\n</styles>';

        let $template = $(swalTemplate);

        let url = laroute.route('api_realiser_commande', {commande: $(this).data('id')});

        swal({
            title: 'Pièces réalisées',
            html: $template.html(),
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmer',
            cancelButtonText: 'Annuler',
            onOpen: function () {
                $('#swal2-content #quantite-realiser').val(quantite_realisee);
                $('#swal2-content .swal-quantite-addon').html('/ ' + quantite);
            },
            preConfirm: function (value) {
                return new Promise(function (resolve, reject) {
                    let quantite_realiser = $('#swal2-content #quantite-realiser').val();


                    if (quantite_realiser == '') {
                        return reject('Merci de préciser un nombre de pièces réalisées (minimum: 0)');
                    }
                    if (quantite_realisee > quantite_realiser) {
                        return reject('Vous devez rentrer un nombre plus grand que ' + quantite_realisee)
                    }

                    return resolve({});
                })
            }
        }).then(function (result) {
            let quantite_realiser = $('#swal2-content #quantite-realiser').val();
            let realiser = 0;

            realiserCommande(realiser, id, quantite_realiser)
        })
    });

    $('.production-checkbox-realiser').on('click', function () {
        let realiser = $(this).prop('checked') ? 1 : 0;
        let id = $(this).data('id');

        realiserCommande(realiser, id, false);
    });
}

function realiserCommande(realiser, id, qtt) {
    let url = laroute.route('api_realiser_commande', {commande: id});

    let data = {
        '_token': $('.token').html(),
        'realiser': realiser,
    }

    if (qtt) {
        data.quantite_realisee = qtt;
    }

    $.post({
        url: url,
        data: data,
        success: function (data) {

            getProductionCommandes();
        },
        error: function (err) {
        }
    })
}


function refreshProductionTables() {
    $('.production-matiere-commande-table').each(function () {
        var nbLigne = $(this ).find(' .commande-production').length;
        var OptionsCollection = PRODUCTION_TABLE_OPTIONS;
        console.log(nbLigne);
        if (nbLigne > 5) {
            console.log("eo");
            OptionsCollection.sDom = "rtip";
            OptionsCollection['paging'] = true;
            console.log(OptionsCollection);
        } else {
            OptionsCollection.sDom = "rti";
            OptionsCollection['paging'] = false;
        }
        $(this).DataTable(OptionsCollection);

    })

    // On reattache les events sur les elements raffraichis ici
    attacherRealiserEvents();

    $('.production-commande-detail').on('click', function () {
        getProductionCommandeDetail($(this).data('url'));
    });

}

// autorefresh et notification si nouvelle commande
if ($('.production-commandes-container').length > 0) {
    let pageTitle = document.title;
    // var audio = new Audio($('.refreshDiffLink').data('audio'));
    let commandesNumber = checkCommandesNumber();

    window.setInterval(function () {
        let newCommandesNumber = checkCommandesNumber();
        if ((commandesNumber != newCommandesNumber) && commandesNumber != null) {
            commandesNumber = newCommandesNumber;
            // audio.play();
            document.title = '[Nouvelles commandes] ' + pageTitle;
            getProductionCommandes();
        }
    }, 10000)
}

// Recupere le nombre de commandes depuis l'api
function checkCommandesNumber() {
    var number;
    $.get({
        url: $('.refreshDiffLink').html(),
        async: false,
        success: function (commandesNb) {
            number = commandesNb;
        }
    })
    return number;
}

$('#production-realises-select').on('change', function () {
    setDateDebutInput();
    // getProductionCommandes();
});

// Chargement des commandes à print
$('#production-commandes-export').on('click', function () {
    let dateDebut = $('#mirror-production-date-debut').val();
    let dateFin = $('#mirror-production-date-fin').val();
    let realises = $('#production-realises-select').val();
    let matieres = $('#production-select-matieres-export').val();

    var dataArray = {};

    dataArray.from = dateDebut;
    if (dateFin != '') {
        dataArray.to = dateFin;
    }

    if (realises != 'toutes') {
        dataArray.realisees = realises;
    }

    if (matieres != 0) {
        dataArray.matiere = matieres;
    }

    $.get({
        url: $(this).data('url'),
        data: dataArray,
        success: function (data) {
            $('#printable-table').html(data);
            $('#printable-table table').DataTable(PRINTABLE_TABLE_OPTIONS);
        }
    })
});

// Creation du pdf
$('#production-export-pdf').on('click', function () {
    $('.production-print-checkbox').not(':checked').each(function () {
        let id = $(this).val();
        $('tr#printable-commande-' + id).remove();
    })
    $('.select-print-col').remove();
    $('.table-print th:first-child').remove();
    makePdf();
    // location.reload();
})

$('#production-print-check-all').on('change', function () {
    if ($(this).prop('checked')) {
        $('.production-print-checkbox').prop('checked', true);
    } else {
        $('.production-print-checkbox').prop('checked', false);
    }
});

function makePdf() {
    let specialElementHandlers = {
        '#bypassme': function (element, renderer) {
            return true
        }
    };

    var pdf = new jsPDF('l', 'px', [1680, 1080]);
    var table = $('#printable-table').html();
    // alert(table);
    pdf.fromHTML(
        table,
        15,
        22,
        {
            'width': 1680,
            'elementHandlers': specialElementHandlers
        },
        function (dispose) {
            pdf.save('liste_commandes.pdf');
        }
    );
}

// Refresh de la table print lorsqu'on choisis une matiere
$('#production-print-select-matiere').on('change', function () {
    let url = $(this).data('url');
    // alert(url)
    let dateDebut = $('#mirror-production-date-debut').val();
    let dateFin = $('#mirror-production-date-fin').val();
    let realises = $('#production-realises-select').val();
    let matieres = $(this).val();

    var dataArray = {};

    dataArray.from = dateDebut;
    if (dateFin != '') {
        dataArray.to = dateFin;
    }

    if (realises != 'toutes') {
        dataArray.realisees = realises;
    }

    if (matieres != 0) {
        dataArray.matiere_id = matieres;
    }

    // console.log(dataArray);
    $.get({
        url: url,
        data: dataArray,
        success: function (data) {
            $('#printable-table').html(data);
        }
    })
})

$('.production-commande-detail').on('click', function () {
    getProductionCommandeDetail($(this).data('url'));
});

function getProductionCommandeDetail(url) {
    // let url = $(this).data('url');
    $.get({
        url: url,
        async: false,
        success: function (data) {
            $('#production-commande-detail-body').html(data);
        },
    })
}

if ($('#production-date-debut').length > 0) {
    setDateDebutInput();
}


// Fonctions date

function getTodayDate() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
}

function getTodayDateFormated() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!

    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    today = dd + '/' + mm + '/' + yyyy;
    return today;
}

function getLastYearDate() {
    let day = new Date();
    let dd = day.getDate();
    let mm = day.getMonth() + 1; //January is 0!

    let yyyy = day.getFullYear() - 1;
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    day = yyyy + '-' + mm + '-' + dd;
    return day;
}

function getLastYearDateFormated() {
    let day = new Date();
    let dd = day.getDate();
    let mm = day.getMonth() + 1; //January is 0!

    let yyyy = day.getFullYear() - 1;
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    day = dd + '/' + mm + '/' + yyyy;
    return day;
}


$('.production-commandes-container').on('click', 'td.clickable', function () {
    let $viewButton = $(this).parent('tr').find('.production-commande-detail');
    getProductionCommandeDetail($viewButton.data('url'));
    $('#production-detail-modal').modal('toggle');

});


// refreshProductionTables();
