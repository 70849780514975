// Lien vers la page show depuis le tableau de recap

$('#modal-commande').on('show.bs.modal', function(event){
  let url = $(event.relatedTarget).data('url');
  $.get({
    url: url,
    success: function(data){
      $('#modal-commande .modalbody').html(data);
      $('#modal-commande h2').html("Commande n° " + $(event.relatedTarget).data('reference'));
      addCommandeFormEvents();
    },
    error: function(e){
      console.log(e);
    }
  })
});


$(".delete-commande").click(function(){
    return confirm("Voulez vous supprimer cette commande ?");
});
