
const TABLE_OPTIONS = {
   language: {
       "url": "lang/French.json"
   },
   "oColReorder": {
       "realtime": true
   },
   lengthMenu: [
       [10, 25, 50, 100],
       [10, 25, 50, 100]
   ],
   columnDefs: [
      { targets: 'no-sort', orderable: false },
      // { width: 25, targets: 'button-column' },
      { "searchable": false, "targets": [0,1,4,5,6,7,8,9,10,11,12] }

    ],
   stateSave: true,
   "paging": true,
   "lengthChange": true,
   "ordering": true,
   "info": true,
   "scrollX" : true,
   "iDisplayLength": 10,
   "autoWidth": false,
   "searching": true,
   "order": [[ 3, "asc" ]],
};

let listeDateSearchSettings =
{
 format: 'dd/mm/yyyy',
 minView : 2,
 language: 'fr',
 autoclose: true,
 todayBtn: true,
};

$('#list-date-search').datetimepicker(listeDateSearchSettings);

var CommandesTable = [];

$('.commande-table').each(function(index, table){
  CommandesTable.push($(table).DataTable(TABLE_OPTIONS));
});

// Recherche par référence
$.fn.dataTable.ext.search.push(
    function( settings, data, dataIndex ) {
        var reference_searched = $('#list-reference-search').val();
        var reference = data[2];
        // Si une des références commence par la ref cherchée
        if (reference_searched == '' || (reference.search(reference_searched) == 0))
        {
            return true;
        }
        return false;
    }
);

// Recherche par date
$.fn.dataTable.ext.search.push(
    function( settings, data, dataIndex ) {
        var date_searched = $('#list-date-search').val();
        var date = data[3];
        // Si une des références commence par la ref cherchée
        if (date_searched == '' || date == date_searched)
        {
            return true;
        }
        return false;
    }
);

// Comme le champs de recherche est mémorisé mais caché,
// on le vide pour eviter les mauvaises surprises
$("#commande-table_filter input[type='search']").val('');

$('#list-reference-search').on('keyup', function(){
  commandeListeRedraw();
});

$('#list-date-search').on('change', function(){
  commandeListeRedraw();
});

$('#commande-liste-search-reset').on('click', function(){
  $('#list-date-search').val('');
  $('#list-reference-search').val('');
  commandeListeRedraw();
});

$('.commandes-nav-tabs li').on('click', function(){

  commandeListeRedraw();
})

function commandeListeRedraw()
{
  window.setTimeout(function(){
    $.each(CommandesTable, function(index, table){
      table.draw();
    })

  }, 200);
}
